import React, {useState} from "react"
import {getImage} from "../../lib/dataManipulation"
import {getWorkUri} from "../../lib/utils"
import ArchiveItem from "./archiveItem"

function ProjectArchive({project, works, mainSub}) {
  let [activeItem, setActiveItem] = useState()
  let mainSubImage = getImage(mainSub?.image?.node, "thumb-large")
  let mainSubMedium = mainSub?.mediums.nodes[0]
  let mainSubUri = getWorkUri(mainSub, mainSubMedium?.id, project.id, "project")
  let mainSubMediumName = mainSubMedium?.medium.singular
  works = works?.sort((a, b) => new Date(b.node.date) - new Date(a.node.date))

  return (
    <>
      {mainSub && (
        <ArchiveItem
          large={true}
          {...{activeItem, setActiveItem}}
          id={mainSub.id}
          uri={mainSubUri}
          image={mainSubImage}
          title={(mainSubMediumName ? mainSubMediumName + " - " : "") + mainSub.title}
          content={mainSub.description.content}
        />
      )}
      {works.map(({node: work}, j) => {
        let image = getImage(work.image?.node, "thumbnail")
        let medium = work.mediums.nodes[0]
        let uri = getWorkUri(work, medium?.id, project.id, "project")
        let mediumName = medium?.medium.singular
        return (
          work.id !== mainSub?.id && (
            <ArchiveItem
              {...{activeItem, setActiveItem, uri, image}}
              id={work.id}
              title={(mediumName ? mediumName + " - " : "") + work.title}
              key={j}
              content={work.description.content}
            />
          )
        )
      })}
    </>
  )
}

export default ProjectArchive
