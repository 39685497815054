import React, {useState} from "react"
import {getWorkUri} from "../../lib/utils"
import {getImage} from "../../lib/dataManipulation"
import ArchiveItem from "./archiveItem"
import ArchiveSubLi from "./archiveSubLi"

function MainProjectArchive({project, works}) {
  let [activeSub, setActiveSub] = useState()
  let [activeItem, setActiveItem] = useState()
  project.children.nodes = project.children.nodes?.sort((a, b) => a.termOrder + 0 - (b.termOrder + 0))
  return project.children.nodes.map((child, i) => {
    let attachedWorks = works.filter(work => work.node.projects.nodes.some(el => el.id === child.id))
    let childWorkIds = []
    let mainSub = child.mainSub.mainSub ? child.mainSub.mainSub[0].id : 0

    attachedWorks.forEach(work => childWorkIds.push(work.node.id))
    attachedWorks = attachedWorks?.sort((a, b) => new Date(b.node.date) - new Date(a.node.date)) // order by date
    attachedWorks = attachedWorks?.sort((a, b) => (b.node.id === mainSub) - (a.node.id === mainSub)) // set mainsub first
    let isHidden = activeSub && activeSub !== child.id
    return (
      <React.Fragment key={i}>
        <ArchiveSubLi sub={child} {...{setActiveSub, isHidden}} />

        {attachedWorks.map(({node: work}, j) => {
          let image = getImage(work.image?.node, "thumbnail")
          let medium = work.mediums.nodes[0]
          let uri = getWorkUri(work, medium?.id, child.id, "project")
          let mediumName = medium?.medium.singular
          return (
            <ArchiveItem
              {...{activeItem, setActiveItem, uri, image, isHidden}}
              id={work.id}
              title={(mediumName ? mediumName + " - " : "") + work.title}
              key={j}
              content={work.description.content}
            />
          )
        })}
      </React.Fragment>
    )
  })
}

export default MainProjectArchive
